// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
//import YouTube from 'react-youtube'

const SecondPage = (props: PageProps) => (
  <Layout>
  <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default SecondPage
